// ! FIX: Missing description

export const stageConfig = {
    UK: {
        env: 'stage', // ! FIX: Why we need another env properties, it is env.dev.ts?
        api: {
            baseUrl: 'https://stage.fantasticxrm.com/api/v2.2/',
            accounts: 'https://accounts-stg.1dxr.com/'
        },
        country: 'UK',
        XApplication: '4rd4spxv8eg4tsl5l9bs4nmeh92i47z2fx8oyljnwcoqkq0kyxd74brtwgre3xj7',
        XProfile: '2',
        cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
        googleApiKey: '', // AIzaSyCNN907cEUcIScGXC6w45IeNix1IYw711o
        googleSuggestAddress: false, // This is only for address not postcode.
        paymentSecurityBadge: {
            enable: false,
            href: ''
        },
        defaultPhoneCode: '44'
    },

    AUS: {
        env: 'stage', // ! same
        api: {
            baseUrl: 'https://stage.fantasticxrm.com/api/v2.2/',
            accounts: 'https://accountsaus-stg.1dxr.com/'
        },
        country: 'AUS',
        XApplication: 'toLSKpKvQHrzHKRCjKfa6dT21Xada4THjsSR0gnN5gM68IcQWli1fCFUEbmIHGdU',
        XProfile: '6',
        cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
        googleApiKey: '',
        googleSuggestAddress: false, // This is only for address not postcode.
        paymentSecurityBadge: {
            enable: true,
            href: ''
        },
        defaultPhoneCode: '61'
    }
};
