export const productionConfig = {
    UK: {
        env: 'production',
        api: {
            baseUrl: 'https://login.fantasticxrm.com/api/v2.2/',
            accounts: 'https://accounts.fantasticservices.com/'
        },
        country: 'UK',
        XApplication: '4rd4spxv8eg4tsl5l9bs4nmeh92i47z2fx8oyljnwcoqkq0kyxd74brtwgre3xj7',
        XProfile: '2',
        cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
        googleApiKey: '', // AIzaSyCGzk0Pinx3WIj7si0R7p6BwgIQChoNYvE
        googleSuggestAddress: false, // This is only for address not postcode.
        paymentSecurityBadge: {
            enable: false,
            href: ''
        },
        defaultPhoneCode: '44'
    },

    AUS: {
        env: 'production',
        api: {
            baseUrl: 'https://login.fantasticxrm.com/api/v2.2/',
            accounts: 'https://accounts.fantasticservicesgroup.com.au/'
        },
        country: 'AUS',
        XApplication: 'toLSKpKvQHrzHKRCjKfa6dT21Xada4THjsSR0gnN5gM68IcQWli1fCFUEbmIHGdU',
        XProfile: '6',
        cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
        googleApiKey: '',
        googleSuggestAddress: false, // This is only for address not postcode.
        paymentSecurityBadge: {
            enable: false,
            href: ''
        },
        defaultPhoneCode: '61'
    }
};


