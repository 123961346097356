export const localhostConfig = {
    UK: {
        env: 'localhost',
        api: {
            baseUrl: 'https://dev.fantasticxrm.com/api/v2.2/',
            accounts: 'https://accounts-dev.1dxr.com/'
        },
        country: 'UK',
        XApplication: '4rd4spxv8eg4tsl5l9bs4nmeh92i47z2fx8oyljnwcoqkq0kyxd74brtwgre3xj7',
        XProfile: '2',
        cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
        googleApiKey: '', //AIzaSyCNN907cEUcIScGXC6w45IeNix1IYw711o
        googleSuggestAddress: false, // This is only for address not postcode.
        paymentSecurityBadge: {
            enable: false,
            href: ''
        },
        defaultPhoneCode: '44'
    },

    AUS: {
        env: 'localhost',
        api: {
            baseUrl: 'https://dev.fantasticxrm.com/api/v2.2/',
            accounts: 'https://accounts-dev.1dxr.com/'
        },
        country: 'AUS',
        XApplication: 'toLSKpKvQHrzHKRCjKfa6dT21Xada4THjsSR0gnN5gM68IcQWli1fCFUEbmIHGdU',
        XProfile: '6',
        cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
        googleApiKey: '',
        googleSuggestAddress: false, // This is only for address not postcode.
        paymentSecurityBadge: {
            enable: false,
            href: ''
        },
        defaultPhoneCode: '61'
    }
};
